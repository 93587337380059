/*************************************************************************************************/
/** JavaScript Bundle - Frontend *****************************************************************/
/*************************************************************************************************/

(function($) {

function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

/*************************************************************************************************/
/** Responsive Transition Handler ****************************************************************/
/*************************************************************************************************/

var respond_size = 0;
var breakdebug_timeout;
 
function be_responsive(size) {
  console.log("Breakpoint hit: " + size);

  // Breakpoint debugger
  try {
    $('#breakpoint').css('display', 'block');
    switch (respond_size) {
      case 320:
        $('#breakpoint').html('Breakpoint: 320 [XS]');
      break;
      case 500:
        $('#breakpoint').html('Breakpoint: 500 [SM]');
      break;
      case 768:
        $('#breakpoint').html('Breakpoint: 768 [MD]');
      break;
      case 992:
        $('#breakpoint').html('Breakpoint: 992 [LG]');
      break;
      case 1280:
        $('#breakpoint').html('Breakpoint: 1280 [XL]');
      break;
      default: 
        $('#breakpoint').html('UNKNOWN');
      break;
    }  
    clearTimeout(breakdebug_timeout);
    breakdebug_timeout = setTimeout(function() { $('#breakpoint').css('display', 'none'); }, 1000);
  } catch(e) {
  }
  
}

function width_to_respond_size(width) {
  if (width < 500) {
    return 320;
  } else if (width < 768) {
    return 500;
  } else if (width < 992) {
    return 768;
  } else if (width < 1280) {
    return 992;
  } else {
    return 1280;  
  }
}

/*************************************************************************************************/
/** Fix Vertical Heights *************************************************************************/
/*************************************************************************************************/

var fixvh_timeout;
var fixvh_lastsize = $(window).innerWidth();
var fixvh_active = false;
var fixvh_enabled = true;

function fixvh_reset() {
  if (fixvh_enabled === true) {
    if (fixvh_active === true) {
      if (fixvh_lastsize !== $(window).innerWidth()) {
        // Reset all the heights if we've resized...
        console.log("Resetting all .fixvh heights and .fixtp padding-tops...");
        $('.fixvh').each(function() {
          $(this).height('');        
        });
        $('.fixtp').each(function() {
          $(this).css('padding-top', '');
        });
        fixvh_lastsize = $(window).innerWidth();
        fixvh_active = false;
      }
    }
  }
}

function fixvh() {
  var current_height = 0;
  var current_padding = 0;
  var computed_padding = '';
  var new_height = 0;
  var new_padding = 0;
  if (fixvh_enabled === true) {
    // Check for and fix any odd-number heights among our elements...
    $('.fixvh').each(function() {    
      current_height = $(this).height();
      if (current_height % 2 > 0) {
        new_height = current_height - 1;
        console.log(".fixvh fixed an odd height!", current_height, '->', new_height, $(this).context);
        $(this).height(new_height);
        fixvh_active = true;
      }
    });
    // And also check for and fix any odd-number padding-tops...
    $('.fixtp').each(function() {    
      current_padding = parseInt($(this).css('padding-top').replace('px', ''));
      computed_padding = current_padding + 'px';
      if (current_padding % 2 > 0) {
        new_padding = current_padding - 1;
        console.log(".fixtp fixed an odd padding-top!", $(this).css('padding-top'), '->', new_padding, $(this).context);          
        $(this).css('padding-top', new_padding + 'px');
        fixvh_active = true;
      } else if ($(this).css('padding-top') !== computed_padding) {
        new_padding = computed_padding;
        console.log(".fixtp fixed a fractional padding-top!", $(this).css('padding-top'), new_padding, $(this).context);
        $(this).css('padding-top', new_padding + 'px');
        fixvh_active = true;
      }
    });        
  }
}

/*************************************************************************************************/
/** Sticky Footer ********************************************************************************/
/*************************************************************************************************/

var original_footer_margin = -1;
var stick_footer_timeout;
var stick_footer_checks = 0;
function stick_footer() {
  var viewport_height = $(window).height();
  var document_height = $('html').innerHeight();
  var cur_diff = viewport_height - document_height;
  var cur_margin = parseInt($('#page-wrapper').css('padding-bottom'));
  var new_margin = 0;
  stick_footer_checks ++;
  // If this is the first time we've messed
  // with the margins, remember the current
  // value as the original minimum...
  if (original_footer_margin === -1) {
    original_footer_margin = cur_margin;
  }
  if (viewport_height > document_height) {
    // Pad the footer if the page isn't tall
    // enough for it to appear at the bottom...
    console.log('Viewport height at ' + viewport_height.toString() + ' exceeds document height at ' + document_height.toString());    
    new_margin = cur_diff + cur_margin;    
    console.log('Footer top margin currently ' + cur_margin.toString() + ', needs to be ' + new_margin.toString());
    $('#page-wrapper').css('padding-bottom', new_margin.toString() + 'px');
  } else if ((document_height > viewport_height) && (cur_margin > original_footer_margin)) {
    // Otherwise if the page is too tall, check
    // to see if we can shed any margin to bring
    // it back to the original...
    console.log('Document height at ' + document_height.toString() + ' exceeds viewport height at ' + viewport_height.toString());
    new_margin = cur_diff + cur_margin;
    if (new_margin < original_footer_margin) {
      new_margin = original_footer_margin;
    }
    console.log('Footer top margin currently ' + cur_margin.toString() + ', needs to be ' + new_margin.toString());
    $('#page-wrapper').css('padding-bottom', new_margin.toString() + 'px');
  }
  if (stick_footer_checks >= 5) {
    stick_footer_checks = 0;
  } else {
    // Check everything again in a little while...    
    clearTimeout(stick_footer_timeout);    
    stick_footer_timeout = setTimeout(function() { stick_footer(); }, 10);
  }  
}

/*************************************************************************************************/
/** Dark Mode ************************************************************************************/
/*************************************************************************************************/

function setDarkMode() {
  localStorage.setItem('modepref', 'dark');
  $('body').addClass('darkmode');
}
function setLightMode() {
  localStorage.setItem('modepref', 'light');
  $('body').removeClass('darkmode');
}

$('.darkmode-button').on('click', function(){
  setDarkMode();
});
$('.lightmode-button').on('click', function(){
  setLightMode();
});

/*************************************************************************************************/
/** Accordions ***********************************************************************************/
/*************************************************************************************************/


$(document).ready(function(){
  $("a.trigger_accordion__container").click(function(){
    var id = $(this).data('id');
    var target = document.getElementById(id);
    $('html, body').animate({
      scrollTop: $(target).offset().top - 41 
    }, 700);
  });
  $("a.trigger_accordion__item").click(function(){
    var id = $(this).data('id');
    var target = document.getElementById(id);
    var collapse_id = '#' + $(this).data('collapse');
    $(collapse_id).addClass("show");
    $('html, body').animate({
      scrollTop: $(target).offset().top - 10
    }, 700);
  });
});

/*************************************************************************************************/
/** Sage JavaScript Routing **********************************************************************/
/*************************************************************************************************/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
  // All pages
  'common': {
    init: function() {
      // JavaScript to be fired on all pages

      $.ajaxSetup ({ cache: false });

      /* Light/dark mode setting
       */
      // FIXME: possible listen for mode change, although that's fairly overkill

      /* Master resize hook
       */
      $(window).resize(function() {
        var current_size = width_to_respond_size($('#mq').width());
        if (respond_size !== current_size) {
          // If we have reached a breakpoint, call the
          // other function that handles this...
          respond_size = current_size;
          be_responsive(respond_size);
        }

        // Reposition the footer if needed...
        clearTimeout(stick_footer_timeout);    
        stick_footer_timeout = setTimeout(function() { stick_footer(); }, 10);

        // Reset any fixing .fixvh or .fixtp may be doing...
        // NOTE: no debounce on this if we want smooth resizing!
        fixvh_reset();    

        // Fix any odd vertical-centered heights...
        clearTimeout(fixvh_timeout);    
        fixvh_timeout = setTimeout(function() { fixvh(); }, 500);    
      });

      respond_size = width_to_respond_size($('#mq').width());
      be_responsive(respond_size);
      stick_footer();

      /* Search toggle
      */
      $('.nav-search, .gematsu-header__search-close').on('click', function(){
        $('.gematsu-header__search').toggleClass('search-active');
        if ($('.gematsu-header__search').hasClass('search-active')) {
          $('input#search').focus();
        }
      });

      /* Dropdowns not to stay focused
       */
      $('.dropdown-toggle').on('focus', function() {
        $(this).blur();
      });

      /* Carousel controls not to stay selected
       */
      $('.carousel-controls__next, .carousel-controls__prev').on('focus', function() {
        $(this).blur();
      });

      /* Featherlight gallery;
      */    
      $('.gallery .gallery-item a').featherlightGallery({
        gallery: {
          fadeIn:   100,
          fadeOut:  100
        },
        openSpeed:  150,
        closeSpeed: 150
      });

    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  },
  // Exclusives
  'page_template_page_exclusives': {
    init: function() {
      
      // Legacy js taken from exclusive page
      $("#timedexclusive").click(function(){
        $("table .timedexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#addexclusive").click(function(){
        $("table .addexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#genexclusive").click(function(){
        $("table .genexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#consoleexclusive").click(function(){
        $("table .consoleexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#fullexclusive").click(function(){
        $("table .fullexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#peripheralexclusive").click(function(){
        $("table .peripheralexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#platformexclusive").click(function(){
        $("table .platformexclusive").toggle();
        $(this).toggleClass("highlight");
      });
      
      $("#importpending").click(function(){
        $("table .importpending").toggle();
        $(this).toggleClass("highlight");
      });
    
    }
  },
  // Game pages
  'games_template_default': {
    init: function() {
      // JavaScript to be fired on the game homepage

      /* Switch active tab based on anchor
      */
      if (window.location.hash) {
        var desired_tab = window.location.hash.replace('#', '');
        if ($('.tab-pane#' + desired_tab).length) {          
          console.log("Switching to '" + desired_tab + "' tab");
          $('.tab-pane').removeClass('active');
          $('.tab-pane#' + desired_tab).addClass('active');
          $('.gematsu-gameheader__nav li a').removeClass('active');
          $('.gematsu-gameheader__nav li a').each(function(i) {
            if ($(this).html() === desired_tab) {
              $(this).addClass('active');
            }
          });
          // Remove the hash so we don't end up scrolling there
          window.location.hash = '';
        } else { 
          console.log("Can't switch to '" + desired_tab + "' tab because one doesn't exist");
        }
      }

    },
    finalize: function() {
      // JavaScript to be fired on the game homepage, after the init JS      
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

/*************************************************************************************************/
/*************************************************************************************************/
/*************************************************************************************************/

})(jQuery); // Fully reference jQuery after this point.